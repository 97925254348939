import Vue from 'vue';
import { apiV2 as api } from '@/services/api';

const mutations = {
  EDIT_ATTRIBUTE: (state, { dataSourceName, attributeName, data }) => {
    const attributeIndex = state.metadata[dataSourceName].attributes_schema.attributes.findIndex(
      attribute => attribute.name === attributeName
    );
    if (attributeIndex >= 0)
      Vue.set(state.metadata[dataSourceName].attributes_schema.attributes, attributeIndex, {
        ...state.metadata[dataSourceName].attributes_schema.attributes[attributeIndex],
        ...data,
      });
  },
};

const actions = {
  async getTile(store, payload) {
    const { layerId, envelope, features_filter, attributes, zxy } = payload;
    return await api.post(
      `/layers/${layerId}/mvt`,
      { features_filter, envelope, attributes, zxy, use_cache: true },
      { responseType: 'arraybuffer', skipDefaultErrorHandler: true }
    );
  },
  async getLayersMetadata({ commit, dispatch }, params) {
    const { withAdditionalMetadata } = params;
    delete params.withAdditionalMetadata;
    if (withAdditionalMetadata) await dispatch('getAdditionalLayersMetadata');
    const r = await api.get('/datasources', { params });
    const metadata = r.data.data.reduce((total, current) => ({ ...total, [current.name]: current }), {});
    commit('SET_METADATA', metadata);
  },
  async editDataSourceMetadata({ commit }, payload) {
    const { name, body } = payload;
    await api.put(`/datasources/${name}`, body);
    if (body.target_srid) {
      // W backendzie jest inny klucz układu wsp zwracany, a inny w requeście edycji
      // Na razie podmieniam ręcznie, ale docelowo powinny być ujednolicone
      body.srid = body.target_srid;
      delete body.target_srid;
    }
    commit('EDIT_DATA_SOURCE_METADATA', payload);
  },
  async addAttribute({ commit }, payload) {
    const { data, dataSourceName } = payload;
    const r = await api.post(`/datasources-attributes/${dataSourceName}`, data);
    commit('ADD_ATTRIBUTE', { dataSourceName, data: r.data.data });
  },
  async editAttribute({ commit }, payload) {
    const { data, dataSourceName, attributeName } = payload;
    await api.put(`/datasources-attributes/${dataSourceName}/${attributeName}`, data);
    commit('EDIT_ATTRIBUTE', payload);
  },
  async deleteAttribute({ commit }, payload) {
    const { attributeName, dataSourceName } = payload;
    await api.delete(`/datasources-attributes/${dataSourceName}/${attributeName}`);
    commit('DELETE_ATTRIBUTE', payload);
  },
};

export default {
  namespaced: true,
  mutations,
  actions,
};
